var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"companyAddressWrapper"},[_c('div',{staticClass:"SearchbarWrapper"},[_c('div',{staticClass:"searchbar"},[_c('Search',{attrs:{"placeholder":_vm.placeholder,"isAdvanceSearch":true,"isOnlyAdvanceSearch":true,"isAdvanceAFilter":true,"filterConfig":_vm.selectedSearchConfig},on:{"queryString":_vm.queryString,"filterObject":_vm.advanceSearch,"clearFilter":_vm.advanceSearch},scopedSlots:_vm._u([{key:"buttonSection",fn:function(){return [_c('v-btn',{attrs:{"disabled":_vm.checkDownloadButtonStatus,"color":"secondary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadReport()}}},[_vm._v("Download Report")])]},proxy:true}])})],1),_c('div',{staticClass:"datepicker"},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.datePickerDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.datePickerDate=$event},"update:return-value":function($event){_vm.datePickerDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date Range","readonly":"","outlined":""},on:{"click":_vm.dataSelector},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateDialog),callback:function ($$v) {_vm.dateDialog=$$v},expression:"dateDialog"}},[_c('v-date-picker',{attrs:{"range":"","type":"month","scrollable":""},model:{value:(_vm.datePickerDate),callback:function ($$v) {_vm.datePickerDate=$$v},expression:"datePickerDate"}},[_c('div',{staticClass:"date-picker-action-section"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errorMessage),expression:"!errorMessage"}],staticClass:"date-error-message"},[_vm._v(" "+_vm._s(_vm.dateErrorMessage)+" ")]),_c('div',{staticClass:"action-buttons"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.resetDatePicker}},[_vm._v(" Reset ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.cancelDatePicker}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"disabled":!_vm.errorMessage,"text":"","color":"primary"},on:{"click":_vm.submitDatePicker}},[_vm._v(" OK ")])],1)])])],1)],1)]),(_vm.totalCount === 0)?_c('div',{staticClass:"content-error-message"},[_vm._v(" No Followup entries. Please add followup entries to see the reports ")]):_c('div',{staticClass:"info-table"},[_c('v-data-table',{attrs:{"items-per-page":_vm.pageSize,"hide-default-footer":"","headers":_vm.headers,"item-key":"_id","items":_vm.dataList,"fixed-header":true},scopedSlots:_vm._u([{key:"item.contact_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contact_number ? item.contact_number : "-")+" ")]}},{key:"item.date_of_travel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_of_travel ? _vm.getFormattedDate(item.date_of_travel, "MMMM Do YYYY dddd") : "-")+" ")]}},{key:"item.reminder_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reminder_date ? _vm.getFormattedDate(item.reminder_date, "MMMM Do YYYY dddd") : "-")+" ")]}},{key:"item.invoice_no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoice_no ? item.invoice_no : "-")+" ")]}},{key:"item.payment_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment_type ? item.payment_type : "-")+" ")]}},{key:"item.currency_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency_type ? item.currency_type : "-")+" ")]}},{key:"item.company_data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.company_data ? item.company_data.name : "-")+" ")]}},{key:"item.amount_received",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount_received ? item.amount_received : "-")+" ")]}}],null,true)})],1),(_vm.isPaginationRequired)?_c('div',{staticClass:"paginationWrapper text-center"},[_c('v-pagination',{staticClass:"pagination-component",attrs:{"length":Math.ceil(_vm.fetchCount / _vm.pageSize),"total-visible":_vm.paginationTotalVisible},on:{"input":_vm.updatedPageNo},model:{value:(_vm.pageNo),callback:function ($$v) {_vm.pageNo=$$v},expression:"pageNo"}}),_c('div',{staticClass:"page-size-dropdown"},[_c('v-autocomplete',{attrs:{"items":_vm.pageSizeList,"auto-select-first":"","solo":"","dense":""},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }